import * as React from "react";
import { useFormik } from "formik";
import { saveAs } from "file-saver";
import { useState } from "react";
import "./styles.css";

export default function App() {
  performance.clearMeasures();
  const formik = useFormik({
    initialValues: {
      startFirstOrder: 1,
      endFirstOrder: 8,
      startSecondOrder: 9,
      endSecondOrder: 10,
      startThirdOrder: 11,
      endThirdOrder: 13
    },
    onSubmit: values => {
      console.log(JSON.stringify(values, null, 2));
    }
  });

  const [loading, setLoading] = useState(false);

  function download(filename: string, text: string) {
    performance.mark("download");
    const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "data.tri");
    performance.measure("downloadTime", "download");
    console.log(performance.getEntriesByType("measure"));
  }

  const data = new Set<string>();

  const startTri = async () => {
    setLoading(true);
    const files = Array.from(
      (document?.getElementById("input") as HTMLInputElement)?.files!
    );

    performance.mark("start sort");
    await Promise.all(
      files.map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = e => resolve(true);
          reader.onload = e => {
            (e?.target?.result as string)
              .split(/\r?\n/)
              .filter(line => line.trim().length > 0)
              .forEach(line => data.add(line));
          };
          reader.readAsText(file);
        });
      })
    );
    performance.measure("sort time", "start sort");

    const sortedData = Array.from(data.values()).sort((a, b) => {
      const [aFirst, aSecond, aThird] = [
        a.slice(formik.values.startFirstOrder - 1, formik.values.endFirstOrder),
        a.slice(
          formik.values.startSecondOrder - 1,
          formik.values.endSecondOrder
        ),
        a.slice(formik.values.startThirdOrder - 1, formik.values.endThirdOrder)
      ];
      const [bFirst, bSecond, bThird] = [
        b.slice(formik.values.startFirstOrder - 1, formik.values.endFirstOrder),
        b.slice(
          formik.values.startSecondOrder - 1,
          formik.values.endSecondOrder
        ),
        b.slice(formik.values.startThirdOrder - 1, formik.values.endThirdOrder)
      ];

      const firstDiff = aFirst.localeCompare(bFirst);
      if (firstDiff !== 0) {
        return firstDiff;
      }

      const secondDiff = aSecond.localeCompare(bSecond);
      if (secondDiff !== 0) {
        return secondDiff;
      }

      const thirdDiff = aThird.localeCompare(bThird);
      if (thirdDiff !== 0) {
        return thirdDiff;
      }
      return 0;
    });
    console.log("data.size", data.size);
    download(
      "sorted.txt",
      sortedData.filter(line => line.trim().length > 0).join("\n")
    );
    setLoading(false);
  };
  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen">
      <h1 className="text-4xl mb-24">Tri JSC</h1>
      <div className="flex flex-col">
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="startFirstOrder"
            >
              startFirstOrder
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="startFirstOrder"
              type="number"
              name="startFirstOrder"
              onChange={formik.handleChange}
              value={formik.values.startFirstOrder}
            />
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="endFirstOrder"
            >
              endFirstOrder
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="endFirstOrder"
              type="number"
              name="endFirstOrder"
              onChange={formik.handleChange}
              value={formik.values.endFirstOrder}
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="startSecondOrder"
            >
              startSecondOrder
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="startSecondOrder"
              type="number"
              name="startSecondOrder"
              onChange={formik.handleChange}
              value={formik.values.startSecondOrder}
            />
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="endSecondOrder"
            >
              endSecondOrder
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="endSecondOrder"
              type="number"
              name="endSecondOrder"
              onChange={formik.handleChange}
              value={formik.values.endSecondOrder}
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="startThirdOrder"
            >
              startThirdOrder
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="startThirdOrder"
              type="number"
              name="startThirdOrder"
              onChange={formik.handleChange}
              value={formik.values.startThirdOrder}
            />
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="endThirdOrder"
            >
              endThirdOrder
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="endThirdOrder"
              type="number"
              name="endThirdOrder"
              onChange={formik.handleChange}
              value={formik.values.endThirdOrder}
            />
          </div>
        </div>
        <div className="my-4">
          <input type="file" multiple id="input" />
        </div>
        <button
          onClick={startTri}
          type="button"
          disabled={loading}
          className={`flex justify-between bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center ${
            loading ? "cursor-not-allowed" : ""
          }`}
        >
          Genere et trie le fichier{" "}
          {loading && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="spin"
            >
              <path
                className="fill-current w-4 h-4 mr-2"
                d="M6 18.7V21a1 1 0 0 1-2 0v-5a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H7.1A7 7 0 0 0 19 12a1 1 0 1 1 2 0 9 9 0 0 1-15 6.7zM18 5.3V3a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1h-5a1 1 0 0 1 0-2h2.9A7 7 0 0 0 5 12a1 1 0 1 1-2 0 9 9 0 0 1 15-6.7z"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
}
